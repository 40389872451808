<template>
  <section>
    <form class="columns is-multiline" @submit="onNextStep">
      <div class="column is-12">
        <div class="box">
          <div class="columns is-multiline">
            <div class="column is-6 is-paddingless">
              <h1>Direcciones</h1>
            </div>
            <div class="column is-6 has-text-right">
              <button
                class="button"
                type="button"
                @click="showModalAddAddress"
              >
                Nueva ubicación
              </button>
            </div>
            <template v-if="addressNewLocation" >
              <div class="column is-6">
                  <label class="label">Dirección</label>
              </div>
              <div class="column is-6 has-text-right">
                <i
                  class="material-icons icon"
                  @click="clearNewAddress"
                >
                  delete
                </i>
              </div>
              <div class="column is-12">
                <div class="field">
                  <div class="control">
                    <input
                      type="text"
                      class="input"
                      disabled="true"
                      :value="addressNewLocation.name + ' (' + addressNewLocation.neighborhood + ' ' + addressNewLocation.deputation + ' ' + addressNewLocation.city + ' ' +  (addressNewLocation.zip_code || '') + ' )'"
                    >
                  </div>
                </div>
                <product-ubication
                  :lat="addressNewLocation.latitude"
                  :lng="addressNewLocation.longitude"
                />
              </div>
            </template>
            <div class="column is-12" v-if="isLoading">
              <loading-component  />
            </div>
            <template v-for="(address, index) of addressList">
              <div class="column is-12" :key="index">
                <label class="checkbox" :class="{'has-text-danger': isErrorAddress}">
                  <input
                    type="checkbox"
                    :checked="addressSelected.find(option => option === address.id)"
                    @click="addAddress(address.id)"
                  >
                  {{address.label}}
                </label>
              </div>
            </template>
            <div class="column is-12">
              <label class="checkbox" :class="{'has-text-danger': isErrorAddress}">
                <input
                  type="checkbox"
                  v-model="personalDelivery"
                >
                Entrega personal (Ponte de acuerdo con el vendedor para recibir tu producto)
              </label>
            </div>
            <div class="column is-12">
              <label class="radio" :class="{'has-text-danger': isErrorAddress}">
                <input
                  type="checkbox"
                  v-model="otherMehod"
                >
                Otro método de envio (Uber, Rappi, paqueteria)
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-12">
        <div class="columns is-multiline is-mobile">
          <div class="column is-6 has-text-left">
            <button
              class="button is-link is-light"
              type="button"
              @click="onStepBack"
            >
              Regresar
            </button>
          </div>
          <div class="column is-6 has-text-right">
            <button
              class="button button-givu"
              type="submit"
            >
              Continuar
            </button>
          </div>
        </div>
      </div>
    </form>
    <modal-add-address
      v-if="modalAddAddress"
      :close="showModalAddAddress"
      :saveAddress="onSaveAddress"
    />
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'Address',
  components: {
    ProductUbication: () => import('@/components/Products/ProductUbication'),
    ModalAddAddress: () => import('@/components/NewProduct/ModalAddAddress.vue'),
    LoadingComponent: () => import('@/components/ui/Loading')
  },
  mounted () {
    this.addressNewLocation = this._addressNewLocation
    this.addressSelected = this._addressSelected
    this.otherMehod = this._otherMehod
    this.personalDelivery = this._personalDelivery
    this.addressSelected = this._addressSelected
  },
  data () {
    return {
      addressList: '',
      addressNewLocation: null,
      addressSelected: [],
      otherMehod: false,
      personalDelivery: false,
      isErrorAddress: false,
      modalAddAddress: false,
      isLoading: true
    }
  },
  async beforeMount () {
    let responseAddress = await this.getAddressAdminDefault()
    this.addressList = this.formattedAddress(responseAddress)
    this.isLoading = false
  },
  computed: {
    ...mapState({
      _addressNewLocation: state => state.newProduct.addressNewLocation,
      _addressSelected: state => state.newProduct.addressSelected,
      _otherMehod: state => state.newProduct.otherMehod,
      _personalDelivery: state => state.newProduct.personalDelivery
    })
  },
  methods: {
    ...mapMutations(['updateStepNewProduct', 'setNewProductValues']),
    ...mapActions(['getAddressAdminDefault']),
    formattedAddress (adressList) {
      return adressList.map(item => ({
        ...item,
        label: `${item.name}  ( ${item.neighborhood}, ${item.deputation}, ${item.city} ${item.zip_code ? `, ${item.zip_code}` : ''})`,
        value: item.id
      }))
    },
    addAddress (id) {
      let isExist = this.addressSelected.find(item => item === id)
      if (isExist) {
        let removeCategory = this.addressSelected.filter(item => item !== id)
        this.addressSelected = removeCategory
      } else {
        this.addressSelected = [...this.addressSelected, id]
      }
      this.isErrorAddress = false
    },
    showModalAddAddress () {
      this.modalAddAddress = !this.modalAddAddress
      this.isErrorAddress = false
    },
    onSaveAddress (newAddress) {
      if (this.addressNewLocation) this.addressNewLocation = null
      setTimeout(() => {
        this.addressNewLocation = newAddress
        this.showModalAddAddress()
      }, 500)
    },
    clearNewAddress () {
      this.addressNewLocation = null
    },
    onStepBack () {
      this.updateStepNewProduct(1)
    },
    async onNextStep (e) {
      e.preventDefault()

      if (!this.addressSelected.length && !this.addressNewLocation && !this.otherMehod && !this.personalDelivery) {
        this.isErrorAddress = true
        return true
      }
      this.setNewProductValues({
        addressNewLocation: this.addressNewLocation,
        addressSelected: this.addressSelected,
        otherMehod: this.otherMehod,
        personalDelivery: this.personalDelivery
      })
      this.updateStepNewProduct(3)
    }
  },
  watch: {
    otherMehod: function (newV, oldV) {
      this.isErrorAddress = false
    },
    personalDelivery: function (newV, oldV) {
      this.isErrorAddress = false
    }
  }
}
</script>

<style scoped lang="scss">
  .icon {
    cursor: pointer;
  }
  h1 {
    border-bottom: 1px solid #cd3d27;
    padding-bottom: 0.3rem;
    margin-bottom: 0.5rem;
  }
  .checkbox, .radio {
    font-size: 12px
  }
</style>
